var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c(
        "div",
        [
          _c(
            "div",
            { staticClass: "header" },
            [
              _c("el-button", { on: { click: _vm.goBackAction } }, [
                _vm._v("Back"),
              ]),
              _c("span", { staticClass: "ref" }, [
                _vm._v(" / "),
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.estimate_id) +
                      " / Invoice " +
                      _vm._s(_vm.invoice.sequence) +
                      "/" +
                      _vm._s(_vm.payment_plan.quantity) +
                      " "
                  ),
                ]),
              ]),
              !_vm.editingInvoice.invoice_id
                ? _c(
                    "el-popconfirm",
                    {
                      staticClass: "delete-confirm",
                      attrs: {
                        placement: "top",
                        title: "Are you sure you want to delete this invoice?",
                        "hide-icon": "",
                      },
                      on: { confirm: _vm.handleDeleteInvoice },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            slot: "reference",
                            plain: "",
                            type: "danger",
                          },
                          slot: "reference",
                        },
                        [_vm._v(" Delete invoice ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("hr"),
          _c("paymentplan-invoice", {
            attrs: {
              payment_plan: _vm.payment_plan,
              invoice: _vm.editingInvoice,
              estimate: _vm.estimate,
            },
            on: { update: _vm.handleInvoiceUpdate },
          }),
          _c(
            "div",
            { staticClass: "section-footer" },
            [
              _vm.editingInvoice.invoice_id
                ? [
                    !_vm.linked_invoice
                      ? _c(
                          "el-alert",
                          { attrs: { closable: false, type: "warning" } },
                          [
                            _vm._v(
                              " This invoice has not been imported. Refresh invoices to link it to this payment plan. "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _c("div", { staticClass: "row" }, [
                      _vm.invoice_url
                        ? _c(
                            "div",
                            { staticClass: "col" },
                            [
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "clipboard",
                                      rawName: "v-clipboard:copy",
                                      value: _vm.invoice_url,
                                      expression: "invoice_url",
                                      arg: "copy",
                                    },
                                  ],
                                  attrs: { readonly: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showNotification(
                                        "Xero invoice link"
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" Xero invoice link ")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "col right" },
                        [
                          _vm.linked_invoice
                            ? _c(
                                "el-button",
                                { on: { click: _vm.handleViewInvoice } },
                                [_vm._v(" View invoice ")]
                              )
                            : _vm._e(),
                          _vm.can_send_email
                            ? [
                                _vm.email_sent
                                  ? _c("div", { staticClass: "email-sent" }, [
                                      _vm._v(" Sent "),
                                    ])
                                  : _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          plain: "",
                                          type: "primary",
                                          loading: _vm.sending,
                                        },
                                        on: { click: _vm.handleSendEmail },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              this.linked_invoice
                                                .sent_to_contact
                                                ? "Resend email"
                                                : "Send email"
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]),
                    _vm.invoice.last_sent
                      ? _c("div", { staticClass: "last-sent" }, [
                          _vm._v(
                            " Last sent " +
                              _vm._s(
                                _vm._f("dateformat")(
                                  _vm.invoice.last_sent.toDate(),
                                  "DD/MM/YYYY"
                                )
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                  ]
                : [
                    _c(
                      "el-alert",
                      { attrs: { type: "info", closable: false } },
                      [
                        _vm._v(
                          " Changes will apply to this invoice only, and do not affect the rest of the payment plan. "
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col right" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { disabled: _vm.is_disabled },
                              on: { click: _vm.handleSaveInvoice },
                            },
                            [_vm._v(" Save changes ")]
                          ),
                          _c(
                            "el-popover",
                            {
                              attrs: { placement: "top" },
                              model: {
                                value: _vm.submitConfirmVisible,
                                callback: function ($$v) {
                                  _vm.submitConfirmVisible = $$v
                                },
                                expression: "submitConfirmVisible",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "send-confirm-popover" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "options" },
                                    [
                                      _c("div", { staticClass: "option" }, [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "label",
                                            class: {
                                              selected: !_vm.useCustomDueDate,
                                            },
                                          },
                                          [_vm._v(" Due date from terms ")]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "due-from-terms" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.due_date_from_terms.format(
                                                    "DD/MM/YYYY"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("el-switch", {
                                        model: {
                                          value: _vm.useCustomDueDate,
                                          callback: function ($$v) {
                                            _vm.useCustomDueDate = $$v
                                          },
                                          expression: "useCustomDueDate",
                                        },
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "option" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "label",
                                              class: {
                                                selected: _vm.useCustomDueDate,
                                              },
                                            },
                                            [_vm._v(" Set due date ")]
                                          ),
                                          _c("el-date-picker", {
                                            attrs: {
                                              size: "small",
                                              format: "dd/MM/yyyy",
                                              clearable: false,
                                            },
                                            on: {
                                              change:
                                                _vm.handleCustomDueDateSet,
                                            },
                                            model: {
                                              value: _vm.dueDateCustom,
                                              callback: function ($$v) {
                                                _vm.dueDateCustom = $$v
                                              },
                                              expression: "dueDateCustom",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("span", { staticClass: "label" }, [
                                    _vm._v(
                                      " The above dates are in " +
                                        _vm._s(_vm.timezone) +
                                        " time "
                                    ),
                                  ]),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary", size: "mini" },
                                      on: { click: _vm.handleSubmitInvoice },
                                    },
                                    [_vm._v(" Confirm & submit ")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-button",
                                {
                                  staticClass: "submit-button",
                                  attrs: {
                                    slot: "reference",
                                    disabled: _vm.editingInvoice.is_scheduled,
                                    loading: _vm.sending,
                                    plain: "",
                                    type: "primary",
                                  },
                                  slot: "reference",
                                },
                                [_vm._v(" Submit to Xero ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
            ],
            2
          ),
          !_vm.has_contact_emails && !_vm.invoice_paid
            ? _c(
                "el-alert",
                {
                  staticClass: "email-warning",
                  attrs: { type: "error", closable: false },
                },
                [
                  _vm._v(" This payment plan is not configured for emailing "),
                  _c(
                    "el-button",
                    {
                      staticClass: "edit-paymentplan-contacts",
                      attrs: { size: "small", plain: "", type: "danger" },
                      on: { click: _vm.handleEditContacts },
                    },
                    [_vm._v(" Edit contacts ")]
                  ),
                ],
                1
              )
            : _vm.can_send_email
            ? _c(
                "el-alert",
                { staticClass: "email-list", attrs: { closable: false } },
                [
                  _vm._v(
                    " The following contacts will receive this invoice when emailed: "
                  ),
                  _c(
                    "ul",
                    [
                      _vm.contact_addressee.name
                        ? _c("li", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.contact_addressee.name) +
                                " <" +
                                _vm._s(_vm.contact_addressee.email) +
                                "> "
                            ),
                          ])
                        : _c("li", [
                            _vm._v(
                              " " + _vm._s(_vm.contact_addressee.email) + " "
                            ),
                          ]),
                      _vm._l(_vm.contact_cc, function (contact) {
                        return _c(
                          "li",
                          { key: contact.email },
                          [
                            _c(
                              "el-tag",
                              { attrs: { size: "mini", type: "default" } },
                              [_vm._v("CC")]
                            ),
                            contact.name
                              ? _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(contact.name) +
                                      " <" +
                                      _vm._s(contact.email) +
                                      "> "
                                  ),
                                ])
                              : _c("span", [
                                  _vm._v(" " + _vm._s(contact.email) + " "),
                                ]),
                          ],
                          1
                        )
                      }),
                      _vm._l(_vm.contact_bcc, function (contact) {
                        return _c(
                          "li",
                          { key: contact.email },
                          [
                            _c(
                              "el-tag",
                              { attrs: { size: "mini", type: "success" } },
                              [_vm._v("BCC")]
                            ),
                            contact.name
                              ? _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(contact.name) +
                                      " <" +
                                      _vm._s(contact.email) +
                                      "> "
                                  ),
                                ])
                              : _c("span", [
                                  _vm._v(" " + _vm._s(contact.email) + " "),
                                ]),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }